"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attachStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _stylesheet = require("../../../../styles/stylesheet");

var isAttached = false;

var attachStyles = function attachStyles(theme) {
  if (!isAttached) {
    var _global;

    isAttached = true;
    (0, _stylesheet.createStyles)({
      '@global': (_global = {
        '.react-calendar': {
          width: 280,
          padding: 5,
          maxWidth: '100%',
          background: 'white',
          border: '1px solid #a0a096',
          borderRadius: 3,
          lineHeight: '1.125em',
          '& button': {
            margin: 0,
            border: 0,
            outline: 'none',
            position: 'relative',
            '&:focus': {
              zIndex: 1
            },
            '&:enabled:hover': {
              cursor: 'pointer'
            }
          }
        },
        '.react-calendar--doubleView': {
          width: 700,
          '& .react-calendar__viewContainer': {
            display: 'flex',
            margin: '-0.5em',
            '& > *': {
              width: '50%',
              margin: '0.5em'
            }
          }
        },
        '.react-calendar__navigation': {
          height: 30,
          fontSize: 14,
          marginBottom: 2,
          '& button': {
            minWidth: 30,
            '&:enabled:hover, &:enabled:focus': {
              color: '#006edc'
            },
            '&[disabled]': {
              backgroundColor: '#f0f0f0'
            }
          },
          '& .react-calendar__navigation__arrow': {
            fontSize: 24,
            paddingBottom: 4,
            height: 30
          }
        },
        '.react-calendar__navigation__label__labelText': {
          fontWeight: 'bold'
        },
        '.react-calendar__month-view__weekdays': {
          textAlign: 'center',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: 12
        },
        '.react-calendar__month-view__weekdays__weekday': {
          padding: 6,
          color: '#006edc'
        },
        '.react-calendar__month-view__weekNumbers': {
          fontWeight: 'bold',
          '& .react-calendar__tile': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 12,
            padding: 'calc(0.75em / 0.75) calc(0.5em / 0.75)'
          }
        },
        '.react-calendar__month-view__days__day--neighboringMonth': {
          color: '#4F4F4F'
        }
      }, (0, _defineProperty2["default"])(_global, ".react-calendar__year-view .react-calendar__tile," + ".react-calendar__decade-view .react-calendar__tile," + ".react-calendar__century-view .react-calendar__tile", {
        padding: '17px 0',
        fontSize: 14
      }), (0, _defineProperty2["default"])(_global, '.react-calendar__month-view__days__day', (0, _defineProperty2["default"])({}, "&:nth-child(-n+7)," + "&:nth-child(n+15):nth-child(-n+21)," + "&:nth-child(n+29)", {
        background: '#f2f6f9'
      })), (0, _defineProperty2["default"])(_global, '.react-calendar__tile', {
        maxWidth: '100%',
        textAlign: 'center',
        padding: '8px 2px',
        background: 'none',
        '&:disabled': {
          backgroundColor: '#f0f0f0'
        },
        '&:enabled:hover, &:enabled:focus': {
          backgroundColor: '#e6e6e6'
        }
      }), (0, _defineProperty2["default"])(_global, '.react-calendar .react-calendar__tile.react-calendar__tile--now', {
        backgroundColor: theme.colors.yellow350,
        '&:enabled:hover, &:enabled:focus': {
          backgroundColor: theme.colors.yellow500
        }
      }), (0, _defineProperty2["default"])(_global, '.react-calendar .react-calendar__tile.react-calendar__tile--hasActive', {
        backgroundColor: '#76baff',
        '&:enabled:hover, &:enabled:focus': {
          backgroundColor: '#a9d4ff'
        }
      }), (0, _defineProperty2["default"])(_global, '.react-calendar .react-calendar__tile.react-calendar__tile--active', {
        background: '#005eaa !important',
        color: 'white',
        '&:enabled:hover, &:enabled:focus': {
          backgroundColor: theme.colors.secondary
        }
      }), (0, _defineProperty2["default"])(_global, '.react-calendar--selectRange .react-calendar__tile--hover', {
        backgroundColor: '#e6e6e6'
      }), _global)
    });
  }
};

exports.attachStyles = attachStyles;